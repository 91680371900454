import React, { useMemo, useState } from "react";
import { Table, Column, HeaderCell, Cell } from "rsuite-table";
import "rsuite-table/dist/css/rsuite-table.css";
import styles from "./RemindersTable.module.scss";
import RFZOReminderModal from "./RFZOReminderModal";
import Button from "../../components/UI/Buttons/Button/Button";
import axios, { baseUrl } from "../../axios/axios";
import { useGlobalContext } from "../../store/context/GlobalContext";
import NotificationMessage from "../../components/UI/NotificationMessage/NotificationMessage";

const allowedModifyStatuses = ["PENDING", "ORDERED", "WAITING"];

const RemindersTable = () => {
  const { remindersList } = useGlobalContext();
  const data = useMemo(() => {
    return remindersList.map((user: any) => {
      return {
        id: user?.id,
        name: user?.userFirstName + " " + user?.userSecondName,
        phone: user?.userPhone || user?.userResponse?.phoneNumber,
        itemCount: user.items.length,
        willBeReadyFrom: user.willBeReadyFrom,
        willBeReadyTo: user.willBeReadyTo,
        email: user.userResponse ? user.userResponse.username : user.userEmail,
        lbo: user.userLBO,
        status: user.status,
        note: user?.note || "/",
        ...user,
      };
    });
  }, [remindersList]);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  return (
    <>
      {showSuccessMessage && (
        <NotificationMessage className={styles.successNotification} onClick={() => setShowSuccessMessage(false)}>
          Uspesno obrisan podsetnik
        </NotificationMessage>
      )}
      <Table
        renderEmpty={() => <div className={styles.emptyState}>Podaci nisu pronađeni.</div>}
        data={data}
        height={400}
        bordered
        cellBordered>
        <Column resizable width={70} align="center" fixed>
          <HeaderCell>ID</HeaderCell>
          <Cell dataKey="id" />
        </Column>

        <Column resizable width={200}>
          <HeaderCell>Ime i prezime pacijenta</HeaderCell>
          <Cell dataKey="name" />
        </Column>

        <Column resizable width={200}>
          <HeaderCell>Broj telefona</HeaderCell>
          <Cell dataKey="phone" />
        </Column>

        <Column resizable width={200}>
          <HeaderCell>LBO</HeaderCell>
          <Cell dataKey="lbo" />
        </Column>

        <Column resizable width={200}>
          <HeaderCell>Napomena</HeaderCell>
          <Cell dataKey="note" />
        </Column>

        <Column resizable width={200}>
          <HeaderCell>Status</HeaderCell>
          <Cell>
            {(rowData) => {
              return <>{mapStatuses(rowData.status)}</>;
            }}
          </Cell>
        </Column>

        <Column resizable width={250} align="center">
          <HeaderCell>Akcija</HeaderCell>
          <Cell>
            {(rowData) => (
              <div style={{ display: "flex" }}>
                <RFZOReminderModal
                  customButton={{
                    class: styles.openButton,
                    data: rowData,
                    buttonType: "white",
                    text: "Otvori / Izmeni",
                    mode: "preview",
                    disabled: !allowedModifyStatuses.includes(rowData.status),
                  }}
                />
                <Button
                  disabled={!allowedModifyStatuses.includes(rowData.status)}
                  onClick={() =>
                    deleteReminder(rowData.lbo, rowData.email).then(() => {
                      setShowSuccessMessage(true);

                      setTimeout(() => {
                        setShowSuccessMessage(false);
                      }, 5000);
                    })
                  }
                  style={{ height: "32px" }}
                  buttonType="blue">
                  Obriši
                </Button>
              </div>
            )}
          </Cell>
        </Column>
      </Table>
    </>
  );
};

export default RemindersTable;

const STATUSES: { [key: string]: string } = {
  DELETED: "Obrisan",
  SUSPENDED: "Suspendovan",
  EXPIRED: "Istekao",
  PENDING: "Za slanje",
  WAITING: "Na čekanju za registraciju",
};

const mapStatuses = (status: string): string => {
  return STATUSES[status] ?? status;
};

const marketplaceId = localStorage.getItem("marketplaceId");
const branchMarketplaceId = localStorage.getItem("branchMarketplaceId");

const deleteReminder = (lbo: string, email: string): any => {
  return axios.delete(
    baseUrl +
      `/rfzo_therapy_reminder/marketplace_id/${marketplaceId}/branch_marketplace_id/${branchMarketplaceId}/LBO/${lbo}/user_email/${email}`
  );
};
