import React from "react";
import styles from "./NotificationMessage.module.scss";
import classNames from "classnames";

type Props = {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler;
  className?: string;
};

const NotificationMessage = ({ children, onClick, className }: Props) => {
  console.log(className)
  return (
    <div className={classNames(styles.NotificationMessage, className)} onClick={onClick}>
      {children}
    </div>
  );
};

export default NotificationMessage;
