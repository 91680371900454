import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "rsuite/dist/rsuite.min.css";
import "react-calendar/dist/Calendar.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import OrderSocketProvider from "./store/context/OrderSocket";
import RFZOTherapySocketProvider from "./store/context/RFZOTherapySocket";
import FuzzySortProvider from "./store/context/FuzzySort/FuzzySort";
import { GlobalProvider } from "./store/context/GlobalContext";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Provider store={store}>
    <GlobalProvider>
      <OrderSocketProvider>
        <RFZOTherapySocketProvider>
          <BrowserRouter>
            <FuzzySortProvider>
              <App />
            </FuzzySortProvider>
          </BrowserRouter>
        </RFZOTherapySocketProvider>
      </OrderSocketProvider>
    </GlobalProvider>
  </Provider>
);
