import React, { useEffect, useState } from "react";
import ReactModalWrap from "../../components/UI/Modals/ReactModalWrap/ReactModalWrap";
import Button from "../../components/UI/Buttons/Button/Button";
import styles from "./RFZOTherapyReminderGroupModal.module.scss";
import axios, { baseUrl } from "../../axios/axios";
import TextField from "../../components/TextField/TextField";
import deleteIcon from "../../assets/icons/trash.svg";

type Props = {
  customButton?: any;
};

export default function RFZOTherapyReminderGroupModal({ customButton }: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [groupList, setGroupList] = useState<any[]>([]);

  const [textFieldValue, setTextFieldValue] = useState<string>("");

  useEffect(() => {
    if (isOpen) {
      fetchWrap(setGroupList);
    }
  }, [isOpen]);

  const validate = (value: string) => {
    if (value === "") {
      return true;
    }
    return groupList?.find((group: any) => {
      if (group.name == value) {
        return true;
      } else {
        return false;
      }
    });
  };

  const [inputError, setInputError] = useState<boolean>(false);

  const onChangeWrap = (value: string) => {
    setTextFieldValue(value);
    if (validate(value)) {
      setInputError(true);
    } else {
      setInputError(false);
    }
  };

  return (
    <>
      {!customButton && (
        <Button className={styles.modalButton} onClick={() => setIsOpen(true)} buttonType="blue">
          Tvoje liste
        </Button>
      )}
      {customButton && (
        <Button className={customButton?.class} onClick={() => setIsOpen(true)} buttonType={customButton?.buttonType}>
          {customButton?.text}
        </Button>
      )}
      <ReactModalWrap customStyle={{ content: { width: "400px" } }} isOpen={isOpen} close={() => setIsOpen(false)}>
        <div className={styles.contentWrap}>
          <div className={styles.header}>Tvoje liste</div>
          <div className={styles.listWrap}>
            {groupList?.map((group: any, index: number) => {
              return (
                <div className={styles.listItem}>
                  <span className={styles.listItemName}>{group.name}</span>
                  <button
                    onClick={() => {
                      deleteGroup(group.id).then(() => {
                        fetchWrap(setGroupList);
                      });
                    }}>
                    <img src={deleteIcon} />
                  </button>
                </div>
              );
            })}
          </div>
          <hr />
          <TextField
            value={textFieldValue}
            onChange={(val: any) => onChangeWrap(val)}
            wrapClass={styles.textField}
            validator={validate}
            label="Dodaj novu listu"
            placeholder="Unesite naziv liste"
          />
          {inputError && <div className={styles.inputError}>Lista sa ovim nazivom već postoji.</div>}
          <Button
            disabled={inputError || textFieldValue == ""}
            buttonType="blue"
            onClick={() => {
              createGroup(textFieldValue).then(() => {
                fetchWrap(setGroupList);
                setTextFieldValue("");
              });
            }}>
            Sačuvaj
          </Button>
        </div>
      </ReactModalWrap>
    </>
  );
}

const createGroup = (name: string) => {
  const branchMarketplaceId = localStorage.getItem("branchMarketplaceId");
  const marketplaceId = localStorage.getItem("marketplaceId");

  return axios.post(baseUrl + "/rfzo_therapy_reminder_group", {
    branchMarketplaceId: branchMarketplaceId,
    marketplaceId: marketplaceId,
    name: name,
  });
};

export const fetchAllGroups = () => {
  const branchMarketplaceId = localStorage.getItem("branchMarketplaceId");
  const marketplaceId = localStorage.getItem("marketplaceId");

  return axios.get(
    baseUrl +
      `/rfzo_therapy_reminder_group/branch_marketplace_id/${branchMarketplaceId}/marketplace_id/${marketplaceId}`,
    {}
  );
};

const deleteGroup = (id: string) => {
  return axios.delete(baseUrl + "/rfzo_therapy_reminder_group/rfzo_therapy_reminder_group_id/" + id);
};

const fetchWrap = (setGroupList: any) => {
  fetchAllGroups().then((groupList: any) => {
    setGroupList(groupList.data);
  });
};
