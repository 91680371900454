import React, { useEffect, useState } from "react";
import styles from "./RFZOReminderPage.module.scss";
import DatePicker from "react-datepicker";
import RFZOPatients from "../../components/RFZOComponents/RFZOPatients/RFZOPatients";
import RFZOArticles from "../../components/RFZOComponents/RFZOArticles/RFZOArticles";

type Props = {
  rfzoPatients?: boolean;
  rfzoArticles?: boolean;
};
const RFZOReminderPage = ({ rfzoPatients, rfzoArticles }: Props) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [willBeReadyFrom, setWillBeReadyFrom] = useState<string | null>(null);
  const [willBeReadyTo, setWillBeReadyTo] = useState<string | null>(null);

  useEffect(() => {
    if (startDate) {
      setWillBeReadyFrom(
        startDate.getFullYear().toString() +
          "-" +
          (("0" + (startDate.getMonth() + 1).toString()).slice(-2) +
            "-" +
            ("0" + startDate.getDate().toString()).slice(-2))
      );
    } else {
      setWillBeReadyFrom(null);
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate) {
      setWillBeReadyTo(
        endDate.getFullYear().toString() +
          "-" +
          (("0" + (endDate.getMonth() + 1).toString()).slice(-2) + "-" + ("0" + endDate.getDate().toString()).slice(-2))
      );
    } else {
      setWillBeReadyTo(null);
    }
  }, [endDate]);

  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <div className={styles.RFZOReminderPage}>
      <div className={styles.ComponentHeader}>
        <div className={styles.DatePickerWrapper}>Izaberite datum:</div>
        <div>
          <DatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            isClearable
            clearButtonClassName={styles.X}
            placeholderText="Izaberite datum"
            minDate={new Date()}
            className={styles.DatePickerButtonStyle}
            locale="sr"
            dateFormat="dd.MM.yyyy."
          />
        </div>
      </div>
      {willBeReadyFrom && willBeReadyTo && (
        <>
          {rfzoPatients && <RFZOPatients dateFrom={willBeReadyFrom} dateTo={willBeReadyTo} />}
          {rfzoArticles && <RFZOArticles dateFrom={willBeReadyFrom} dateTo={willBeReadyTo} />}
        </>
      )}
    </div>
  );
};

export default RFZOReminderPage;
