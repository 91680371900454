import React, {useEffect, useState} from 'react';
import styles from "./HeaderComponent.module.scss";
import Logo from "../UI/Logo/Logo";
import {ReactComponent as UserIcon} from "../../assets/icons/user-icon.svg";
import {useDispatch, useSelector} from "react-redux";
import {statusActions} from "../../store/status-slice";
import {ReactComponent as FullScreenIcon} from "./../../assets/icons/fullScreen-icon.svg";
import {RootState} from "../../store";
import StatusComponent from "../StatusComponent/StatusComponent";
import HeaderMenu from "./HeaderMenu/HeaderMenu";
import { useLocation } from 'react-router-dom';

const HeaderComponent = () => {

    const dispatch = useDispatch();
    const marketplaceInfo = useSelector((state:RootState) => state.auth.marketplaceInfo)
    const isAdmin = useSelector((state: RootState) => state.auth.isAdmin);
    const isPharmacist = useSelector((state: RootState) => state.auth.isPharmacist);
    const statusComponentVisible = useSelector((state: RootState) => state.statusSlice.statusScreenVisible);

    const [isFullScreen, isFullScreenChange] = useState<boolean>(true);
    const [isAdminState, setIsAdminState] = useState(false);
    const [isDriverState, setIsDriverState] = useState(false);
    const [isPharmacistState, setIsPharmacistState] = useState(false);
    const [isCnCUser, setIsCnCUser] = useState(false);
    const [RFZOMenuOpen, setRFZOMenuOpen] = useState(false);

    useEffect(()=> {
        if (marketplaceInfo && marketplaceInfo.takesOrders !== 1) {
            showStatusScreen();
        }
    })

    useEffect(() => {
        if (isAdmin || localStorage.getItem("roles")?.includes("ADMIN")) {
            setIsAdminState(true);
        } else if (isPharmacist || localStorage.getItem("roles")?.includes("PHARMACIST_SUPPORT_USER")) {
            setIsPharmacistState(true);
        } else if(localStorage.getItem("roles")?.includes("DELIVERY_USER")) {
            setIsDriverState(true)
            return
        }
        else {
            setIsCnCUser(true)
        }
    }, [])

    const toggleFullScreen = () => {
        let doc = window.document;
        let docEl = doc.documentElement;

        let requestFullScreen = docEl.requestFullscreen;
        let cancelFullScreen = doc.exitFullscreen;

        if (!doc.fullscreenElement) {
            requestFullScreen.call(docEl);
            isFullScreenChange(!isFullScreen);
        } else {
            cancelFullScreen.call(doc);
            isFullScreenChange(!isFullScreen);
        }
    }

    const showStatusScreen = () => {
        dispatch(statusActions.toggleStatusScreen(true));
    }

    const toggleRFZOMenu = () => {
        setRFZOMenuOpen(!RFZOMenuOpen)
    }
    const location = useLocation()
    const linkMapping = {
      "order-processing": "Pregled porudžbina",
      "statistics": "Statistika",
      "rfzo-patient-reminder": "Podsetnik Pacijenti",
      "rfzo-article-reminder": "Podsetnik artikli",
      "patients": "Omiljena apoteka",
    };
    const currentLink:string = location.pathname.replace("/", "") || "order-processing"
    const textToShow: any = linkMapping[currentLink as keyof typeof linkMapping] || ""

    return (
        <div className={styles.Header}>
            <Logo/>
            <div className={styles.ActionsWrapper}>
                {isCnCUser &&
                    <div onClick={toggleRFZOMenu}
                         className={styles.RFZOMenu}
                         style={{backgroundColor: RFZOMenuOpen ? "#DEDFE1" : "inherit"}}>
                        <span>{textToShow}</span>
                        {RFZOMenuOpen && <HeaderMenu/>}
                    </div>
                }
                <UserIcon className={marketplaceInfo && marketplaceInfo.takesOrders === 1 ? styles.UserIconBlue : styles.UserIconRed}
                          onClick={showStatusScreen}/>
            </div>

            {statusComponentVisible &&
                <StatusComponent isAdmin={isAdminState} isDriver={isDriverState} isPharmacist={isPharmacistState}/>
            }
        </div>
    );
};

export default HeaderComponent;
