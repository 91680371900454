import React, { useEffect, useState } from "react";
import ReactModalWrap from "../../components/UI/Modals/ReactModalWrap/ReactModalWrap";
import Button from "../../components/UI/Buttons/Button/Button";
import styles from "./RFZOReminderModal.module.scss";
import axios, { baseUrl } from "../../axios/axios";
import TextField from "../../components/TextField/TextField";
import SearchBar from "../../components/UI/Modals/RFZOTherapyRequestModal/SearchWrapper/SearchBar/SearchBar";
import RFZOTherapyReminderGroupModal, { fetchAllGroups } from "./RFZOTherapyReminderGroupModal";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { fetchItemNames } from "../../store/rfzoTherapy-actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import RFZOArticleModal from "../../components/UI/Modals/ReminderModal/RFZOArticleModal/RFZOArticleModal";
import ArticleItem from "../../components/UI/Modals/ReminderModal/ArticleItem/ArticleItem";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { useGlobalContext } from "../../store/context/GlobalContext";

type Props = {
  customButton?: any;
  onSubmit?: any;
};

const THERAPY_REQUEST_INIT = {
  userLbo: "",
  userFirstName: "",
  userSecondName: "",
  userEmail: "",
  userAuthorizedId: localStorage.getItem("id"),
  userId: null,
};

const MODAL_DATA_INIT = {
  willBeReady: null,
  lastTherapyDate: null,
  note: "",
  userPhone: null,
};

const SELECTED_GROUPS_INIT: any[] = [];

const SELECTED_ARTICLES_INIT: any[] = [];

export default function RFZOReminderModal({ customButton, onSubmit }: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [currentArticle, setCurrentArticle] = useState<any>(null);

  const dispatch = useDispatch();
  const itemNames = useSelector((state: RootState) => state.rfzoTherapy.itemNames);

  const [allGroups, setAllGroups] = useState<any[]>([]);

  const [modalData, setModalData] = useState<any>(MODAL_DATA_INIT);
  const [searchParams, setSearchParams] = useSearchParams();

  const [articleList, setArticlesList] = useState<any[]>(SELECTED_ARTICLES_INIT);
  const [therapyRequest, setTherapyRequest] = useState<any>(THERAPY_REQUEST_INIT);

  useEffect(() => {
    if (isOpen) {
      fetchAllGroups().then((groups) => setAllGroups(groups.data));
      dispatch<any>(fetchItemNames());
    } else {
      setTherapyRequest(THERAPY_REQUEST_INIT);
      setArticlesList(SELECTED_ARTICLES_INIT);
      setModalData(MODAL_DATA_INIT);
      setSelectedGroup(SELECTED_GROUPS_INIT);
    }
  }, [isOpen]);

  const onSubmitArticle = (article: any) => {
    console.log(article);
    setArticlesList((prev) => [...prev, article]);
    setCurrentArticle(null);
  };

  const customClickHandler = (val: any) => {
    console.log(val);
    setCurrentArticle(val);
  };

  const removeItemFromArticleList = (article: any) => {
    console.log(article);
    setArticlesList((prev) => {
      const temp = prev.filter((i) => i.itemId != article.itemId);
      return temp;
    });
  };

  const [selectedGroup, setSelectedGroup] = useState<any[] | null>(SELECTED_GROUPS_INIT);

  const [favoriteUsers, setFavoriteUsers] = useState<any[]>([]);

  useEffect(() => {
    if (isOpen) {
      fetchFavoriteUsers().then((res) => setFavoriteUsers(res.data));
    }
  }, [isOpen]);

  const validatorFunction = (therapyRequest: any) => {
    if (therapyRequest.userLbo.length != 11) {
      return true;
    }
    return false;
  };

  const onUserSearchClick = (data: any) => {
    console.log(data);
    setTherapyRequest({
      userLbo: data?.lbo,
      userFirstName: data?.firstName,
      userSecondName: data?.secondName,
      userAuthorizedId: localStorage.getItem("id"),
      userEmail: data?.username,
      userId: data?.id,
    });
    setModalData({
      ...modalData,
      userPhone: data?.phoneNumber,
    });
  };

  const [showNewUserFields, setNewUserFields] = useState<boolean>(false);

  const previewMode = customButton?.mode === "preview";

  useEffect(() => {
    if (isOpen && customButton) {
      const modalDataPassed = customButton?.data;
      setTherapyRequest({
        userLbo: modalDataPassed?.userLBO,
        userFirstName: modalDataPassed?.userFirstName,
        userSecondName: modalDataPassed?.userSecondName,
        userEmail: modalDataPassed?.email,
        userId: modalDataPassed?.userResponse?.id || 1,
        userAuthorizedId: localStorage.getItem("id"),
      });

      const willBeReady = moment(customButton?.data?.willBeReadyFrom).toDate();
      const lastTherapyDate = moment(customButton?.data?.willBeReadyTo).toDate();
      setModalData({
        willBeReady: willBeReady,
        lastTherapyDate: lastTherapyDate,
        note: modalDataPassed?.note,
        userPhone: modalDataPassed?.userPhone,
      });
      console.log(customButton?.data);
      const articles = customButton?.data?.items?.map((it: any) => ({
        ...it,
        itemId: it?.itemResponse?.id,
        ...it?.itemResponse,
        articleName: it?.itemResponse?.name,
      }));
      setArticlesList(articles || []);
    }
    setNewUserFields(false);
  }, [customButton, isOpen]);

  const { setRemindersList, fetchWrap } = useGlobalContext();

  const refetchReminders = () => {
    console.log('first')
    const statuses = searchParams.get("filter") || "";

    fetchWrap(statuses, localStorage.getItem("roles")?.includes("ADMIN")).then((response: any) => {
      let filteredReminders = response?.data?.elements;

      if (searchParams.get("search")) {
        filteredReminders = filterByNameAndLBO(searchParams.get("search") || "", filteredReminders);
      }

      setRemindersList(filteredReminders || []);
    });
  };

  return (
    <div style={{ position: "relative" }}>
      {currentArticle && (
        <RFZOArticleModal
          step="any"
          onSubmitArticle={onSubmitArticle}
          show={!!currentArticle}
          selectedItem={currentArticle}
        />
      )}
      {!customButton && (
        <Button className={styles.modalButton} onClick={() => setIsOpen(true)} buttonType="blue">
          Dodaj novog pacijenta
        </Button>
      )}
      {customButton && (
        <Button
          className={customButton?.class}
          disabled={customButton?.disabled}
          onClick={() => setIsOpen(true)}
          buttonType={customButton?.buttonType}>
          {customButton?.text}
        </Button>
      )}
      <ReactModalWrap customStyle={{ content: { width: "550px" } }} isOpen={isOpen} close={() => setIsOpen(false)}>
        <div className={styles.contentWrap}>
          <div className={styles.header}>Dodaj podsetnik za novog pacijenta</div>
          <hr />
          <div className={styles.smallHeader}>Opšte informacije</div>

          <div className={styles.userSection}>
            {!(therapyRequest?.userId || showNewUserFields) && (
              <>
                <SearchBar
                  className={styles.searchBar}
                  label="Pretražite korisnike"
                  customClickHandler={(user: any) => onUserSearchClick(user)}
                  isUsers
                  data={favoriteUsers}
                />

                <Button onClick={() => setNewUserFields(true)} className={styles.addNewPatientButton}>
                  Novi pacijent
                </Button>
              </>
            )}
            {(therapyRequest?.userId || showNewUserFields) && (
              <>
                <Row>
                  <TextField
                    onChange={(val: any) => setTherapyRequest({ ...therapyRequest, userFirstName: val })}
                    wrapClass={styles.textFields}
                    label="Ime"
                    value={therapyRequest?.userFirstName}
                  />
                  <TextField
                    onChange={(val: any) => setTherapyRequest({ ...therapyRequest, userSecondName: val })}
                    wrapClass={styles.textFields}
                    label="Prezime"
                    value={therapyRequest?.userSecondName}
                  />
                </Row>

                <Row>
                  <TextField
                    disabled={previewMode}
                    wrapClass={styles.textFields}
                    onChange={(val: any) => setTherapyRequest({ ...therapyRequest, userLbo: val })}
                    value={therapyRequest?.userLbo}
                    label="LBO"
                    validator={(val: any) => val?.length != 11}
                  />

                  <TextField
                    disabled={previewMode}
                    onChange={(val: any) => setTherapyRequest({ ...therapyRequest, userEmail: val })}
                    wrapClass={styles.textFields}
                    value={therapyRequest?.userEmail}
                    label="Email"
                  />
                </Row>

                <Row>
                  <TextField
                    onChange={(val: any) => setModalData({ ...modalData, userPhone: val })}
                    wrapClass={styles.textFields}
                    value={modalData?.userPhone}
                    label="Broj telefona"
                  />
                </Row>
              </>
            )}
            <hr />
          </div>

          <div className={styles.smallHeader}>Podsetnik</div>

          <label>Proizvodi</label>
          <SearchBar className={styles.searchBar} customClickHandler={customClickHandler} data={itemNames} />
          {articleList.map((article: any, key: number) => (
            <ArticleItem xIcon removeArticle={removeItemFromArticleList} article={article} key={key} />
          ))}

          <Row>
            <div className={styles.dateWrap}>
              <label>Datum propisivanja</label>
              <DatePicker
                disabled={previewMode}
                isClearable={!previewMode}
                popperPlacement="bottom"
                minDate={new Date()}
                dateFormat="yyyy-MM-dd"
                placeholderText="mm/dd/yyyy"
                wrapperClassName={styles.datePickerWrapper}
                selected={modalData?.willBeReady}
                className={styles.DatePickerButtonStyle}
                onChange={(val: any) => setModalData({ ...modalData, willBeReady: val })}
              />
            </div>
            <div className={styles.dateWrap}>
              <label>Poslednja terapija</label>
              <DatePicker
                disabled={previewMode}
                minDate={new Date()}
                isClearable={!previewMode}
                popperPlacement="bottom"
                dateFormat="yyyy-MM"
                placeholderText="Poslednji mesec dospeća terapije"
                wrapperClassName={styles.datePickerWrapper}
                selected={modalData?.lastTherapyDate}
                className={styles.DatePickerButtonStyle}
                showMonthYearPicker
                onChange={(val: any) => setModalData({ ...modalData, lastTherapyDate: val })}
              />
            </div>
          </Row>
          <hr />

          <div className={styles.smallHeader}>Liste</div>
          <Select
            closeMenuOnSelect={false}
            classNamePrefix="itam-select"
            menuPlacement="bottom"
            className={styles.selectList}
            placeholder="Izaberi listu"
            isMulti
            value={selectedGroup}
            onChange={(val: any) => setSelectedGroup(val)}
            options={allGroups?.map((g: any) => ({ value: g?.id, label: g?.name }))}
          />

          <RFZOTherapyReminderGroupModal
            customButton={{
              class: styles.openButton,
              buttonType: "white",
              text: "+ Dodaj novu listu",
              mode: "preview",
            }}
          />

          <hr />

          <div className={styles.smallHeader}>Napomena</div>

          <textarea
            onChange={(e: any) => setModalData({ ...modalData, note: e?.target?.value })}
            value={modalData?.note}
            className={styles.note}
            placeholder="Dodaj napomenu"
          />

          <hr />

          <div className={styles.smallHeader}>Status</div>

          <hr />

          <Button
            disabled={validatorFunction(therapyRequest)}
            buttonType="blue"
            onClick={() => {
              if (customButton) {
                editReminder(modalData, articleList, therapyRequest, selectedGroup).then(() => {
                  setIsOpen(false);
                  onSubmit && onSubmit();
                  refetchReminders();
                });
              } else {
                createNewReminder(modalData, articleList, therapyRequest, selectedGroup).then(() => {
                  setIsOpen(false);
                  onSubmit && onSubmit();
                  refetchReminders();
                });
              }
            }}>
            Sačuvaj
          </Button>
        </div>
      </ReactModalWrap>
    </div>
  );
}

const Row = ({ children }: any) => {
  return <div style={{ display: "flex", justifyContent: "space-between", margin: "20px 0" }}>{children}</div>;
};

const marketplaceId = localStorage.getItem("marketplaceId");
const branchMarketplaceId = localStorage.getItem("branchMarketplaceId");

const fetchFavoriteUsers = () => {
  return axios.get(baseUrl + `/marketplace/favourite_users/${marketplaceId}/${branchMarketplaceId}`, {});
};

const createNewReminder = (data: any, itemList: any, therapyRequest: any, rfzoTherapyReminderGroupsIds: any) => {
  return axios.post(baseUrl + `/rfzo_therapy_reminder`, {
    ...data,
    branchMarketplaceId: branchMarketplaceId,
    marketplaceId: marketplaceId,
    therapyRequest: therapyRequest,
    items: itemList,
    rfzoTherapyReminderGroupsIds: rfzoTherapyReminderGroupsIds?.map((g: any) => g?.value),
  });
};

const editReminder = (data: any, itemList: any, therapyRequest: any, rfzoTherapyReminderGroupsIds: any) => {
  const itemRemap = itemList.map((item: any) => ({
    itemId: item?.id || item.itemId,
    articleName: item?.articleName,
    rfzoQuantity: item?.rfzoQuantity,
    rfzoParticipationPrice: item?.rfzoParticipationPrice,
  }));

  const therapyRequestDraft = {
    userLbo: therapyRequest.userLbo,
    userFirstName: therapyRequest.userFirstName,
    userSecondName: therapyRequest.userSecondName,
    userEmail: therapyRequest.userEmail,
  };

  const dataDraft = {
    userPhone: data.userPhone || "",
    note: data.note || "",
  };

  return axios.put(baseUrl + `/rfzo_therapy_reminder`, {
    ...dataDraft,
    branchMarketplaceId: branchMarketplaceId,
    marketplaceId: marketplaceId,
    therapyRequest: therapyRequestDraft,
    items: itemRemap,
    rfzoTherapyReminderGroupsIds: rfzoTherapyReminderGroupsIds?.map((g: any) => g?.value),
  });
};

const filterByNameAndLBO = (search: string, data: any[]) => {
  console.log(data);
  // Convert search to lowercase for case-insensitive matching
  const lowercasedSearch = search.toLowerCase();

  return data.filter((item) => {
    const { userFirstName, userSecondName, userLBO, note } = item || {};

    // Check if the search term is in userFirstName, userSecondName, or userLBO
    return (
      userFirstName?.toLowerCase()?.includes(lowercasedSearch) ||
      userSecondName?.toLowerCase()?.includes(lowercasedSearch) ||
      userLBO?.toLowerCase()?.includes(lowercasedSearch) ||
      note?.toLowerCase()?.includes(lowercasedSearch)
    );
  });
};
