import React, { useEffect, useState } from "react";
import styles from "./Statistics.module.scss";
import { fetchOrderForStatistics } from "../../store/order-actions";
import SimpleModal from "../../components/UI/Modals/SimpleModal/SimpleModal";
import Checkbox from "../../components/UI/Checkbox/Checkbox";
import { registerLocale } from "react-datepicker";
import sr from "date-fns/locale/sr-Latn";
import Calendar from "react-calendar";
import moment from "moment";
import filterIll from "../../assets/icons/filter-ilustration.svg";
import Button from "../../components/UI/Buttons/Button/Button";
registerLocale("sr", sr);
type Props = {};

function Statistics({}: Props) {
  const [list, setList] = useState<any[]>([]);

  const [showFilters, setShowFilters] = useState(false);

  const [filters, setFilters] = useState<any>({
    createdFrom: moment().subtract(1, "week").toDate(),
    createdTo: moment().toDate(),
    status: "TAKEN,CANCELED,REJECTED",
  });

  useEffect(() => {
    if (filters.status == "") {
      setList([]);
    } else {
      fetchOrderForStatistics(filters).then((res: any) => setList(res.data));
    }
  }, [filters]);

  return (
    <div className={styles.statisticsWrap}>
      <div className={styles.filtersIcon} onClick={() => setShowFilters(true)}>
        <img src={filterIll} />
      </div>
      {showFilters && <Filters hideModal={() => setShowFilters(false)} filters={filters} setFilters={setFilters} />}
      <table className={styles.table}>
        <thead>
          <th>Broj porudžbine</th>
          <th>Ukupna cena</th>
          <th>Status</th>
        </thead>
        <tbody>
          {list.length == 0 && <div className={styles.noResults}>Nema rezultata za izabrane filtere</div>}
          {list?.map((order, index): any => {
            return (
              <tr className={styles.row} key={index}>
                <td className={styles.orderId}>{order.id}</td>
                <td className={styles.totalPrice}>{order?.totalPrice?.toFixed(2)} RSD</td>
                <td className={styles.status}>{statusMapping[order.status]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className={styles.footerWrap}>
        <div className={styles.footerElements}>Ukupno:</div>
        <div className={styles.footerElements}>
          {list
            .reduce((acc, curr) => acc + curr.totalPrice, 0)
            ?.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{" "}
          RSD
        </div>
        <div className={styles.footerElements}>{list.length} porudžbine</div>
      </div>
    </div>
  );
}

export default Statistics;

const STATUSES = ["TAKEN", "REJECTED", "CANCELED"];

export const Filters = ({ filters, setFilters, hideModal, showDate = true }: any) => {
  const filteringStatuses = (status: string) => {
    let currentStatuses = filters?.status ? filters.status.split(",") : [];

    if (currentStatuses.includes(status)) {
      currentStatuses = currentStatuses.filter((s: string) => s !== status);
    } else {
      currentStatuses.push(status);
      currentStatuses = currentStatuses.filter((s: string) => s !== "ALL");
    }

    setFilters({ ...filters, status: currentStatuses.filter(Boolean).join(",") });
  };

  const selectAll = () => {
    setFilters({ ...filters, status: "TAKEN,CANCELED,REJECTED" });
  };
  const deSelectAll = () => {
    setFilters({ ...filters, status: "" });
  };

  return (
    <SimpleModal className={styles.filtersModal} backdropClickHandler={hideModal} show={false}>
      <div className={styles.segment}>
        <div className={styles.header}>Status</div>
        <div className={styles.statusFilter}>
          <div className={styles.header}>Prikazi</div>
          {STATUSES.map((status) => (
            <div className={styles.selectItem}>
              <Checkbox
                value={filters?.status?.includes(status) || filters?.status == "ALL"}
                onChange={() => {
                  filteringStatuses(status);
                }}
              />
              {/* @ts-ignore */}
              {STATUS_LABELS[status]}
            </div>
          ))}
          <div className={styles.selectItem}>
            <Checkbox
              value={filters?.status?.split(",").length == 3}
              onChange={() => (filters?.status?.split(",").length == 3 ? deSelectAll() : selectAll())}
            />
            Svi
          </div>
        </div>
      </div>
      {showDate && (
        <div className={styles.segment}>
          <div className={styles.header}>Period</div>
          <Calendar
            selectRange={true}
            returnValue="range"
            value={[filters?.createdFrom, filters?.createdTo]}
            onChange={(val) => {
              console.log(val);
              if (val && Array.isArray(val)) {
                setFilters({ ...filters, createdFrom: val[0], createdTo: val[1] });
              }
            }}
            maxDate={new Date()}
            locale="sr"
            className={styles.calendarWrap}
          />
        </div>
      )}
      <Button
        buttonType="blue"
        onClick={() => {
          hideModal();
        }}>
        Primeni
      </Button>
    </SimpleModal>
  );
};

const STATUS_LABELS = {
  TAKEN: "Preuzeto",
  REJECTED: "Odbijeno",
  CANCELED: "Otkazano",
  ALL: "Svi",
};

interface Statuses {
  [key: string]: string;
}

const statusMapping: Statuses = {
  CREATED: "Kreirano",
  REQUESTED: "Zatrazeno",
  PREPARED: "Pripremljeno",
  PROCESSED: "Obradjeno",
  TAKEN: "Preuzeto",
  IN_DELIVERY: "Preuzeto",
  REJECTED: "Odbijeno",
  CANCELED: "Otkazano",
  REQUEST_EXPIRED: "Propušteno",
  PROCESSED_WITHOUT_CONTENT: "Odbijeno",
  REJECT_REQUESTED: "Odbijeno",
};
