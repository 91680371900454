import React from "react";
import Modal from "react-modal";
import styles from "./ReactModalWrap.module.scss";
import { ReactComponent as CloseModalSvg } from "../../../../assets/icons/x-icon.svg";
import Button from "../../Buttons/Button/Button";

type Props = {
  isOpen: boolean;
  close: () => void;
  children: React.ReactNode;
  customStyle?: any;
};

function ReactModalWrap({ isOpen, close, children, customStyle = { overlay: {}, content: {} } }: Props) {
  const customStyles = {
    overlay: {
      zIndex: 2,
      backgroundColor: "rgba(8, 49, 39, 0.6)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backdropFilter: "blur(5px)",
      ...customStyle?.overlay,
    },
    content: {
      opacity: 1,
      position: "static",
      padding: "0px",
      right: "auto",
      bottom: "auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      borderRadius: "15px",
      border: 0,
      maxHeight: "99%",
      background: "#F3F6F6",
      ...customStyle?.content,
    },
  };

  return (
    <div>
      <Modal
        preventScroll={true}
        // appElement={document.getElementById("root")}
        style={customStyles}
        onRequestClose={() => close()}
        isOpen={isOpen}
        shouldCloseOnEsc={true}
        closeTimeoutMS={10}
        shouldCloseOnOverlayClick={true}>
        <div className={styles.content}>
          <Button onClick={close} className={styles.collapseButton} variant="white">
            <CloseModalSvg />
          </Button>
          {children}
        </div>
      </Modal>
    </div>
  );
}

export default ReactModalWrap;
