import React, { useEffect, useState } from "react";
import styles from "./RFZOPatientsReminderPage.module.scss";
import RFZOTherapyReminderGroupModal from "./RFZOTherapyReminderGroupModal";
import TextField from "../../components/TextField/TextField";
import RFZOReminderModal from "./RFZOReminderModal";
import FilterModal from "./FilterModal";
import { useLocation, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import RemindersTable from "./RemindersTable";
import NotificationMessage from "../../components/UI/NotificationMessage/NotificationMessage";
import { useGlobalContext } from "../../store/context/GlobalContext";

const RFZOPatientsReminderPage = ({}: any) => {
  const { setRemindersFilter } = useGlobalContext();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setRemindersFilter({
      createdFrom: searchParams.get("createdFrom"),
      createdTo: searchParams.get("createdTo"),
      groups: searchParams.get("groups"),
      statuses: searchParams.get("filter") || "",
    });
  }, [searchParams]);

  const location = useLocation();

  const handleSearch = (val: any) => {
    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.set("search", val);
    setSearchParams(newSearchParams.toString());
  };
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

  return (
    <div className={styles.RFZOReminderPage}>
      {showSuccessMessage && (
        <NotificationMessage className={styles.successNotification} onClick={() => setShowSuccessMessage(false)}>
          Uspesno kreiran podsetnik
        </NotificationMessage>
      )}
      <div className={styles.buttonWrap}>
        <TextField
          onChange={(val: any) => handleSearch(val)}
          placeholder="Ime i prezime ili LBO"
          wrapClass={styles.nameSearch}
        />
        <RFZOReminderModal
          onSubmit={() => {
            setShowSuccessMessage(true);
            setTimeout(() => {
              setShowSuccessMessage(false);
            }, 5000);
          }}
        />
        <RFZOTherapyReminderGroupModal />
        <FilterModal />
      </div>
      <RFZOPatients />
    </div>
  );
};

export default RFZOPatientsReminderPage;

const RFZOPatients = () => {
  const isAdmin = useSelector((state: RootState) => state.auth.isAdmin);

  const { setRemindersList, remindersFilter, fetchWrap } =
    useGlobalContext();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const statuses = searchParams.get("filter") || "";
    fetchWrap(statuses, isAdmin || localStorage.getItem("roles")?.includes("ADMIN")).then((response: any) => {
      let filteredReminders = response?.data?.elements;

      if (searchParams.get("search")) {
        filteredReminders = filterByNameAndLBO(searchParams.get("search") || "", filteredReminders);
      }

      setRemindersList(filteredReminders || []);
    });
  }, [remindersFilter, searchParams.get("search"), searchParams.get("filter")]);

  return (
    <div style={{ backgroundColor: "#fff", padding: "30px", borderRadius: "15px" }}>
      <RemindersTable />
    </div>
  );
};

const filterByNameAndLBO = (search: string, data: any[]) => {
  console.log(data);
  // Convert search to lowercase for case-insensitive matching
  const lowercasedSearch = search.toLowerCase();

  return data.filter((item) => {
    const { userFirstName, userSecondName, userLBO, note } = item || {};

    // Check if the search term is in userFirstName, userSecondName, or userLBO
    return (
      userFirstName?.toLowerCase()?.includes(lowercasedSearch) ||
      userSecondName?.toLowerCase()?.includes(lowercasedSearch) ||
      userLBO?.toLowerCase()?.includes(lowercasedSearch) ||
      note?.toLowerCase()?.includes(lowercasedSearch)
    );
  });
};
