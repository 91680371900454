import React, { useEffect, useState } from "react";
import styles from "./TextField.module.scss";
import classNames from "classnames";

function TextField({
  type = "text",
  className,
  label,
  wrapClass,
  labelClassName,
  placeHolder,
  onChange,
  name,
  validator,
  ...rest
}: any) {
  const { value } = rest;

  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    if(validator && value) {
      setInvalid(validator(value));
    }

  }, [value, validator]);

  return (
    <div className={classNames(wrapClass, styles.inputWrap)}>
      {label && (
        <label htmlFor={name} className={classNames(labelClassName, styles.labelClass)}>
          {label}
        </label>
      )}
      <input
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeHolder}
        className={classNames(styles.inputClass, className, {
          [styles.invalid]: invalid,
        })}
        type={type}
        {...rest}
      />
    </div>
  );
}

export default TextField;
