import React, { useEffect, useState } from "react";
import styles from "./FilterModal.module.scss";
import Button from "../../components/UI/Buttons/Button/Button";
import ReactModalWrap from "../../components/UI/Modals/ReactModalWrap/ReactModalWrap";
import filterIcon from "../../assets/icons/filter-ilustration.svg";
import Calendar from "react-calendar";
import { fetchAllGroups } from "./RFZOTherapyReminderGroupModal";
import { Checkbox } from "rsuite";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";

type Props = {};

export default function FilterModal({}: Props) {
  const [isOpen, setIsOpen] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [allGroups, setAllGroups] = useState<any[]>([]);
  const location = useLocation();

  useEffect(() => {
    if (isOpen) {
      fetchAllGroups().then((res) => setAllGroups(res.data));
    }
  }, [isOpen]);

  const navigate = useNavigate();

  const clearAllFilters = () => {
    navigate(location.pathname, { replace: true });
  };

  console.log(location.search);

  return (
    <>
      <Button className={styles.modalButton} onClick={() => setIsOpen(true)}>
        <img alt="filter icon" src={filterIcon} />
      </Button>
      <ReactModalWrap isOpen={isOpen} close={() => setIsOpen(false)} customStyle={{ content: { width: "400px" } }}>
        <div className={styles.modalContent}>
          <div className={styles.header}>
            Filteri{" "}
            {location?.search && (
              <Button onClick={clearAllFilters} className={styles.clearFilters}>
                Poništi sve filtere
              </Button>
            )}
          </div>
          <hr />
          <div className={styles.smallHeader}>Datum dospeća RFZO terapije</div>
          <Calendar
            selectRange={true}
            returnValue="range"
            value={[searchParams.get("createdFrom"), searchParams.get("createdTo")]}
            onChange={(val) => {
              if (val && Array.isArray(val)) {
                const from = moment(val[0]).format("YYYY-MM-DD");
                const to = moment(val[1]).format("YYYY-MM-DD");
                const newSearchParams = new URLSearchParams(location.search);
                newSearchParams.set("createdFrom", from);
                newSearchParams.set("createdTo", to);

                setSearchParams(newSearchParams.toString());
              }
            }}
            minDate={new Date()}
            locale="sr"
            className={styles.calendarWrap}
          />
          <hr />
          <div className={styles.smallHeader}>Liste</div>
          {allGroups.map((group) => {
            const selectedGroups = decodeURIComponent(searchParams.get("groups") || "").split(",");
            const isSelected = selectedGroups.includes(group.id.toString());

            const handleCheckboxChange = () => {
              const updatedGroups = isSelected
                ? selectedGroups.filter((id) => id !== group.id.toString()) // Remove group
                : [...selectedGroups, group.id.toString()]; // Add group

              const newSearchParams = new URLSearchParams(location.search);
              newSearchParams.set("groups", updatedGroups.join(","));

              setSearchParams(newSearchParams.toString());
            };

            return (
              <div key={group.id} className={styles.groupItem}>
                <Checkbox checked={isSelected} onChange={handleCheckboxChange} />
                {group.name}
              </div>
            );
          })}

          <hr />

          <div className={styles.smallHeader}>Status</div>

          {["PENDING", "ORDERED"].map((status, index) => {
            const selectedGroups = decodeURIComponent(searchParams.get("filter") || "")
              .split(",")
              .filter((group) => group); // Filter out any empty strings

            const isSelected = selectedGroups.includes(status);

            const handleCheckboxChange = () => {
              const updatedGroups = isSelected
                ? selectedGroups.filter((id) => id !== status) // Remove group
                : [...selectedGroups, status]; // Add group

              const newSearchParams = new URLSearchParams(location.search);
              newSearchParams.set("filter", updatedGroups.join(","));

              setSearchParams(newSearchParams.toString());
            };

            return (
              <div key={index} className={styles.groupItem}>
                <Checkbox checked={isSelected} onChange={handleCheckboxChange} />
                {mapStatuses(status)}
              </div>
            );
          })}

          <hr />
          <Button onClick={() => setIsOpen(false)} className={styles.submitButton} buttonType="blue">
            Primeni
          </Button>
        </div>
      </ReactModalWrap>
    </>
  );
}

const STATUSES: { [key: string]: string } = {
  ORDERED: "Korisnik je rezervisao terapiju nakon podsetnika",
  PENDING: "Korisnik je preuzeo aplikaciju ",
};

const mapStatuses = (status: string): string => {
  return STATUSES[status] ?? status;
};
