import React, { createContext, useContext, useState } from "react";
import axios, { baseUrl } from "../../axios/axios";

const branchMarketplaceId = localStorage.getItem("branchMarketplaceId");
const marketplaceId = localStorage.getItem("marketplaceId");

// Create the context
const GlobalContext = createContext<any>({});

// Create the provider component
export const GlobalProvider = ({ children }: any) => {
  const [remindersList, setRemindersList] = useState([]);
  const [remindersFilter, setRemindersFilter] = useState<any>({});
  const { createdFrom, createdTo, groups } = remindersFilter || {};

  const fetchUserReminders = (statuses: any = "") => {
    console.log("fired");
    console.log(statuses);
    const filterStatus = (statuses || "PENDING,WAITING,ORDERED,EXPIRED")
      .split(",")
      .filter((i: any) => i)
      .map((i: any) => `'${i}'`)
      .join(",");

    return axios.get(baseUrl + `/rfzo_therapy_reminder`, {
      params: {
        branchMarketplaceId: branchMarketplaceId,
        marketplaceId: marketplaceId,
        from: createdFrom,
        to: createdTo,
        groupIds: groups,
        page: 0,
        numOfReturns: 1000,
        filter: filterStatus ? `status in (${filterStatus})` : undefined,
      },
    });
  };
  const fetchUserRemindersAsAdmin = (statuses: any = "") => {
    return axios.get(baseUrl + `/rfzo_therapy_reminder`, {
      params: {
        from: createdFrom,
        to: createdTo,
        groupIds: groups,
        page: 0,
        numOfReturns: 1000,
        filter: statuses
          ? `status:${statuses
              ?.split(",")
              .map((i: any) => `'${i}'`)
              .join(",")}`
          : "",
      },
    });
  };

  const fetchWrap = (statuses: any = "", isAdmin: any) => {
    if (isAdmin) {
      return fetchUserRemindersAsAdmin(statuses);
    } else {
      return fetchUserReminders(statuses);
    }
  };

  return (
    <GlobalContext.Provider
      value={{
        fetchWrap,
        fetchUserReminders,
        fetchUserRemindersAsAdmin,
        remindersList,
        setRemindersList,
        remindersFilter,
        setRemindersFilter,
      }}>
      {children}
    </GlobalContext.Provider>
  );
};

// Hook to use the global context
export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error("useGlobalContext must be used within a GlobalProvider");
  }
  return context;
};
