import React, { useMemo, useState } from "react";
import styles from "../../ArticlesComponents/ArticlesTable.module.scss";
// @ts-ignore
import { useSortBy, useTable } from "react-table";
import Button from "../../UI/Buttons/Button/Button";
import ReminderModal from "../../UI/Modals/ReminderModal/ReminderModal";

type Props = {
  usersData: any[];
};

const initialData = {
  userFirstName: "",
  userSecondName: "",
  userLbo: "",
  phoneNumber: "",
  date: null,
  lastMonth: null,
};

const OurPatientsTable = ({ usersData }: Props) => {
  const data = useMemo(() => {
    return usersData.map((user: any) => {
      return {
        col0: user.id,
        col1: user.firstName + " " + user.secondName,
        col2: user.phoneNumber,
        col3: user.lbo,
      };
    });
  }, [usersData]);

  const [modalData, setModalData] = useState<any>(null);

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "col0",
      },
      {
        Header: "Ime i prezime pacijenta",
        accessor: "col1",
      },
      {
        Header: "Broj telefona",
        accessor: "col2",
      },
      {
        Header: "LBO pacijenta",
        accessor: "col3",
      },
    ],
    []
  );

  const tableInstance = useTable({ columns, data }, useSortBy);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;


  const onReminderSubmit = () => {
    setModalData(null)
  }

  return (
    <>
      {!!modalData && (
        <ReminderModal onSubmit={() => onReminderSubmit()} data={modalData} backdropClickHandler={() => setModalData(null)} on show={!!modalData} />
      )}
      <table {...getTableProps()} style={{ border: "solid 1px blue" }}>
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {column.render("Header")}
                    <span>{column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : ""}</span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: any) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className={styles.Tr}>
                {row.cells.map((cell: any) => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default OurPatientsTable;
